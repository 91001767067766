/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Quagga from '@ericblade/quagga2';
import { Alert, Image } from 'react-bootstrap';
import ScanErrorAudio from '../../assets/audio/scan-error.mp3';
import QRCodeImage from '../../assets/img/qr-code-image.png';

const FrameScanner = (props) => {
    const { FrameScanningModal, setFrameScanningModal, setisScan, ProductDetailsState, setProductDetailsState } = props;
    const [ scanResult, setScanResult ] = useState({
        success: "",
        error: "",
    });
    const [retry, setRetry] = useState(false);

    useEffect(() => {
        if (!retry) {
          startScanner();
        }
        return () => {
          stopScanner(); // Clean up the scanner when component unmounts
        };
    }, [retry]);

    const startScanner = () => {
        const scannerElement = document.querySelector('#scanner');
        if (!scannerElement) {
            console.error('Scanner DOM element not found.');
            return;
        };

        Quagga.init({
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: scannerElement, // the DOM element to use for the camera's viewfinder
            constraints: {
                facingMode: 'environment', // Use the back camera if available
            }
          },
          decoder: {
            readers: ['code_128_reader'], // Specify the barcode type(s) here
          },
        }, (err) => {
          if (err) {
            console.error(err);
            setScanResult({
                success: "",
                error: 'Error initializing barcode scanner',
            });
            setRetry(true);
            return;
          }
          Quagga.start();
        });
    
        Quagga.onDetected(handleDetected);
        Quagga.onProcessed(handleProcessed);
    };

    const stopScanner = () => {
        Quagga.stop();
        Quagga.offDetected(handleDetected);
        Quagga.offProcessed(handleProcessed);
    };
    
    const handleDetected = (result) => {
        if (result && result.codeResult && result.codeResult.code) {
            setisScan(true);
            setScanResult({
                success: "Frame number successfully scanned.",
                error: "",
            });
            setFrameScanningModal({
                ...FrameScanningModal,
                open: false,
            });
            setProductDetailsState({
                ...ProductDetailsState,
                FrameNumber: result.codeResult.code,
                frame_auto_scanner: true,
            });
            stopScanner();
        } else {
            setScanResult({
                success: "",
                error: 'Failed to detect barcode. Please try again.',
            });
            setTimeout(() => {
                setProductDetailsState({
                    ...ProductDetailsState,
                    FrameNumber: "",
                    frame_auto_scanner: false,
                });
            }, 2500);
            setRetry(true);
        }
    };

    const handleProcessed = (result) => {
        if (result && result.codeResult && result.codeResult.error) {
            setScanResult({
                success: "",
                error: 'Error in processing barcode.',
            });
        }
    };
    
    // close frame scanner
    const CloseScanner = () => {
        stopScanner(); // Properly stop the scanner
        setFrameScanningModal({
            ...FrameScanningModal,
            open: false,
        });
        setProductDetailsState({
            ...ProductDetailsState,
            FrameNumber: "",
            frame_auto_scanner: false,
        });
        setScanResult({
            success: "",
            error: "",
        });
        setRetry(true); // Set retry to true to ensure scanner restarts on reopen
    };

    return (
        <div className="framescanner_modal">
            <i className="bi bi-x-lg close_scanner_btn" onClick={CloseScanner}></i>
            
            <div className="frma_scanner_box">
                <div id="scanner" style={{ width: '100%', height: '400px' }}>
                    <Image src={QRCodeImage} className='qrscanning_image' alt='scanner' />
                </div>
            </div>
            
            <h4>Scan The Barcode</h4>

            {scanResult?.success !== "" && (<div className='error_message_scanning'>
                <Alert variant="primary">
                    {scanResult?.success}
                </Alert>
            </div>)}

            {scanResult?.error && (
                <div className='error_message_scanning'>
                    <Alert variant="danger">
                        {scanResult?.error}
                    </Alert> 
                </div>
            )}

            {scanResult?.error !== "" && (
                <audio
                    src={ScanErrorAudio}
                    autoPlay
                />
            )}
        </div>
    );
};

export default FrameScanner;